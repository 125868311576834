<template>
    <v-row class="pa-2">
        <suspendCard @close="close"
                     @switchIcon="switchIcon"
                     :dialog="showDialog"
                     :card="cardDetail"></suspendCard>
        <v-col cols="12"
               lg="6"
               md="12"
               sm="12"
               v-for="(action, idx) in cardActions"
               :key="idx"
               v-if="action.visible" class="pa-2">
            <v-card class="balance-card-background d-flex align-center pa-1"
                    style="box-shadow: none !important;"
                    @click="onActionClick(action.path)">
                <!-- :to="{ name: action.path }" -->
                <div class=""
                     width="40px">
                    <v-img :src="action.image"
                           class="action-image"
                           contain></v-img>
                </div>
                <div class="">
                    <div class="text-color-black font-weight-semibold text-md"
                         style="font-size: 13px;">
                        {{ $t(action.label) }}
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import AppHeader from '../test/AppHeader.vue';
import router from '@/router';
import suspendCard from '@/views/card-settings/SuspendCardDialog.vue';
import
{
    mdiClose
} from '@mdi/js'
import store from '@/store';

export default {
    props: ['card'],
    components: {
        AppHeader,
        suspendCard
    },
    data: () => ({
        icons: {
            mdiClose
        },
        switchOn: [],
        cardDetail: {},
        cardActions: [
            { id: 0, visible: true, path: 'blockCard', image: require('@/assets/images/card-actions/active-block.png'), label: 'Temporary suspend Card' },
            { id: 1, visible: false, path: 'cardlimit', image: require('@/assets/images/card-actions/limit.png'), label: 'Change Card limit' },
            { id: 2, visible: true, path: 'view-pin', image: require('@/assets/images/card-actions/pin.png'), label: 'View Card PIN' },
            // { id: 3, visible: true, path: 'billingDispute', image: require('@/assets/images/card-actions/report.png'), label: 'Report billing dispute' },
            { id: 4, visible: true, path: 'card-issue', image: require('@/assets/images/card-actions/issue.png'), label: 'I have a Card issue' },
            { id: 5, visible: false, path: 'applySupplementary', image: require('@/assets/images/card-actions/supply.png'), label: 'Supplementary Card contact details' },
            { id: 5, visible: true, path: 'personal-information', image: require('@/assets/images/card-actions/supply.png'), label: 'Personal Details' },
        ],
        showDialog: false,
    }),
    mounted()
    {
        let singleCard = this.card[0];

        if (singleCard.isSusByUser)
        {
            this.cardActions[0].label = 'UnSuspend Card';
            this.cardActions[0].path = 'blockCard'
        }
        else
        {
            this.cardActions[0].label = 'Temporary suspend Card';
            this.cardActions[0].path = 'blockCard'
        }
        if (!singleCard.isActive)
        {
            this.cardActions[0].label = 'Activate Card';
            this.cardActions[0].path = 'activeCard'
        }
        // else
        // {
        //     this.cardActions[0].label = 'Activate Card';
        //     this.cardActions[0].path = 'activeCard'
        // }
        if (!singleCard.isPrimary)
        {
            this.cardActions[5].visible = true;
            this.cardActions[4].visible = false;
        }
        else
        {
            // this.cardActions[4].visible = false;
        }
        if (singleCard.canChangeLimit)
        {
            this.cardActions[1].visible = true;
        }
    },

    methods: {
        onActionClick(path)
        {
            let cardType = 'primary';

            if (!this.card[0].isPrimary)
            {
                cardType = 'supplimentary'
            }

            if (path == 'blockCard')
            {
                if (this.showDialog == false)
                {
                    this.showDialog = true;
                } else
                {
                    this.showDialog = false
                }
                this.cardDetail = this.card[0];
            }
            else
            {
                router.push({ name: path, params: { cardType: cardType } });
            }
            if (this.card[0].isPrimary)
            {
                store.set('card/details', this.card[0])
            }
            else
            {
                store.set('card/supplementory', this.card[0])
            }
        },
        switchIcon()
        {
            let cardType = 'primary';

            if (!this.card[0].isPrimary)
            {
                cardType = 'supplimentary'
            }
            router.push({ name: 'blockCard', params: { cardType: cardType } });
        },
        close()
        {
            this.showDialog = false;
        },
        back()
        {
            router.push('/card-detail')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.card-action {
    margin-left: 9px;
    background: #E2F1FD;
    width: 29px;
    height: 27px;
    border-radius: 50%;


}

.v-dialog {
    box-shadow: none !important;
}

.switch-btn {
    margin-bottom: -21px;
}

.v-dialog-logout {
    min-width: 250px !important;
    max-width: 500px !important;
    margin: 0 auto;
}

.action-image {
    height: 35px;
    width: 35px;
    margin: 5px;
    // top: 6px;
}
</style>
